import { useKeenSlider } from "keen-slider/react"
import { LinkCard } from "."
import { useMaxWidthBreakpoint } from "../Hooks"
import { Card } from "./ListViews/Card"

export function RecentPages(props) {
    const { title, titleColor } = props

    const [pages, setPages] = React.useState([])

    const [slidesPerView, setSlidesPerView] = React.useState(3.1)
    const [canSlideBack, setCanSlideBack] = React.useState(true)
    const [canSlideForward, setCanSlideForward] = React.useState(true)
    const isSmLarge = useMaxWidthBreakpoint("md")
    const isSm = useMaxWidthBreakpoint("smLarge")
    const recentPagesRef = React.createRef()

    const [sliderRef, slider] = useKeenSlider({
        slides: {
            perView: slidesPerView,
            spacing: 32,
        },
        defaultAnimation: {
            duration: 1000,
        },
        dragSpeed: .8,
        slideChanged(_slider) {
            let slideCount = _slider.size
            let currSlide = _slider.track.details.rel
            let isNegativeSlide = _slider.track.details.abs < 0
            let maxIndex = _slider.track.details.maxIdx

            // Is between first and last possible slide?
            if(currSlide != maxIndex) {
                setCanSlideForward(true)
            }else { // make sure not negative
                setCanSlideForward(false)
            }

            // Not first slide, and the slider count is greater than one
            if(currSlide > 0 && slideCount > 1 && !isNegativeSlide) {
                setCanSlideBack(true)
            }else{
                setCanSlideBack(false)
            }
        }
    })

    React.useEffect(() => {
        if(!localStorage) return

        setPages(JSON.parse(localStorage.getItem("recentPages")))
    }, [])

    React.useEffect(() => {
        if(isSm) {
            setSlidesPerView(1.1)
        }else if(isSmLarge) {
            setSlidesPerView(2.1)
        }else{
            setSlidesPerView(3.1)
        }
    }, [isSmLarge, isSm])

    React.useEffect(() => {
        if(recentPagesRef && recentPagesRef.current) {
            var container = recentPagesRef.current.closest(".row-wrapper")
            if(container) {
                if(!pages || pages.length == 0 ) {
                    container.style.display = "none"
                }else{
                    container.style.display = "block"
                }
            }
        }
    }, [])  
    
    return (pages && pages.length > 0 ?
        <div className="recent-pages slider-wrapper padding-row__bottom--default" ref={recentPagesRef}>
            <div className="slider-wrapper__header">
                <div className="slider-wrapper__title">
                    <h4 className={"m-0" + (titleColor ? " text-" + titleColor : "")}>{title ?? "Senaste besökta sidorna"}</h4>
                </div>
                <div className="slider-wrapper__header-buttons d-md-block d-none">
                    {slider && (
                        <div className="slider-wrapper__arrows">
                            <i className="icon-arrow_left sliding-arrow" onClick={e => e.stopPropagation() || slider.prev() } disabled={!canSlideBack}></i>
                            <i className="icon-arrow_right sliding-arrow" onClick={e => e.stopPropagation() || slider.next() } disabled={!canSlideForward}></i>
                        </div>
                    )}
                </div>
            </div>
            <div className="recent-pages__slider keen-slider" ref={sliderRef}>
                {pages.map(page => 
                    <Card {...page}
                        image={null}
                        title={page.name}
                        key={page.date}
                        className="keen-slider__slide recent-pages__slide">
                    </Card>
                )}
            </div>
        </div>
        : null
    )
}

// export const RecentPagesContainer = () => <Provider store={global.Store}><RecentPagesContainerFunc></RecentPagesContainerFunc></Provider>