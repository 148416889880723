import { LIST_VIEW_TYPES } from "../../Constants/ListViewTypes.Constants"
import { LinkCard, Card } from "./Card"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

export function CardFeed(props) {
    const { isVisible, currentPage, viewType, items, numItemsEachLoad, columns, forceSameHeight } = props
    var textColorClass = "list-view--" + (props.textColor ?? "light")

    const getCard = item => {
        if(!item.isPublished) return null
        
        if(viewType == LIST_VIEW_TYPES.cardFeedLink) {
            // Link card
            return (
                <LinkCard id={item.id} title={item.title} url={item.url} key={"linkcard"+item.id} className="list-view__item"></LinkCard>
            )
        }else{
            // Normal card
            return (
                <Card {...item} currentPage={currentPage} smallImage={viewType == LIST_VIEW_TYPES.cardFeedSmallImage} key={"card"+item.id} className="list-view__item"></Card>
            )
        }
    }


    return (isVisible !== false &&
        forceSameHeight 
        ? <div className={"list-view list-view--same-height " + textColorClass}>
            {items && items.map(item => 
                getCard(item)  
            )}
         </div>
        : <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: viewType == LIST_VIEW_TYPES.cardFeedLink ? 3 : 2, 1311: 3}}>
            <Masonry className={"list-view list-view--masonry " + textColorClass} gutter="32px">
                {items && items.map(item => 
                    getCard(item)  
                )}
            </Masonry>
         </ResponsiveMasonry>
    )
}