export function ProgressBar(props) {
    const { endValue, value, inViewport, forwardedRef } = props

    const [percentage, setPercentage] = React.useState(0)
    const durationInMs = 250

    const animateProgress = () => {
        var goal = value / endValue

        if(goal == 0 || goal == percentage) return

        let startTime = null

        const step = time => {
            if(!startTime) startTime = time
            const progress = Math.min((time - startTime) / durationInMs, 1)
            setPercentage(progress * (goal - percentage) + percentage)

            if(progress < 1)
                window.requestAnimationFrame(step)
        }

        window.requestAnimationFrame(step)
    }

    React.useEffect(() => {
        if(inViewport && forwardedRef) {
            animateProgress()
        }
    }, [inViewport, value])

    return <div className="progress-bar" ref={forwardedRef}>
        <div className="progress-bar__container">
            <div className={"progress-bar__progress" + (percentage == 1 ? " progress-bar__progress--done" : "")} style={{ width: (percentage * 100) + "%" }}>&nbsp;</div>
        </div>
    </div>
}