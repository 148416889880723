import React from "react"
import FadeIn from "react-fade-in"

export function FAQCard(props) {
    const { title, shortAnswer, link, readMoreText } = props
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [isVisible, setIsVisible] = React.useState(false)

    const handleOnFadeComplete = () => {
        if(!isVisible) {
            setIsExpanded(false)
        }
    }

    const handleCardClick = () => {
        if(!isExpanded) {
            setIsExpanded(true)
            setIsVisible(true)
        }else{
            setIsVisible(false)
        }
    }

    return (
        shortAnswer 
            ? <div className="card faq-card" onClick={handleCardClick}>
                <div className="faq-card__header">
                    <div className="faq-card__title">{title}</div>
                    <div className="faq-card__icon">
                        <i className={(!isVisible ? "icon-add" : "icon-remove") + " icon-type--secondary"}></i>
                    </div>
                </div>
                {isExpanded && <FadeIn transitionDuration="200" visible={isVisible} onComplete={handleOnFadeComplete}>
                    <div className="faq-card__body">
                        <div className="faq-card__text">
                            {shortAnswer}
                            {link && <a href={link} className="faq-card__link" onClick={e => e.stopPropagation()}>
                                {readMoreText} <i className="icon-arrow_right"></i>
                            </a>}
                        </div>
                    </div>
                </FadeIn>}
            </div>
            : <a className="card faq-card" href={link}>
                <div className="faq-card__header">
                    <div className="faq-card__title">{title}</div>
                    <div className="faq-card__icon">
                        <i className="icon-arrow_right icon-type--secondary"></i>
                    </div>
                </div>
            </a>
    )
}