export function Label(props) {
    const getParsedCaption = () => {
        var parsedCaption = props.children
        
        // TODO Implement link stuff

        return parsedCaption
    }

    return <label htmlFor={props.fieldId} className="motillo-form__label">
        {getParsedCaption()}
        {props.showIndicator &&
            <span className="motillo-form__required-indicator">{props.indicator}</span>
        }
    </label>
}