import React from "react"

export function StatisticsBlock(props) {
    const { startValue, endValue, duration, unitBeforeValue, caption, inViewport, forwardedRef } = props
    var { unit } = props
    const [currentValue, setCurrentValue] = React.useState(startValue)
    const [isStarted, setIsStarted] = React.useState(false)
    const durationInMs = duration * 1000

    if(unit.toLowerCase() == "sek") {
        // "SEK" needs a space between the unit and the value
        if(unitBeforeValue)
            unit = unit + " "
        else
            unit = " " + unit
    }

    const startAnimation = () => {
        if(startValue == endValue) return

        let startTime = null

        const step = time => {
            if(!startTime) startTime = time
            const progress = Math.min((time - startTime) / durationInMs, 1)
            setCurrentValue(Math.floor(progress * (endValue - startValue) + startValue))
            if(progress < 1)
                window.requestAnimationFrame(step)
        }

        window.requestAnimationFrame(step)

        setIsStarted(true)
    }

    React.useEffect(() => {
        // Start animation when element is in viewport
        if(inViewport && forwardedRef && !isStarted)
            startAnimation()
    }, [inViewport])

    return <div className="statistics-block" ref={forwardedRef}>
        <div className="statistics-block__value-container">
            {unit && unitBeforeValue && <span className="statistics-block__unit">{unit}</span>}
            <span className="statistics-block__value">{currentValue}</span>
            {unit && !unitBeforeValue && <span className="statistics-block__unit">{unit}</span>}
        </div>
        {caption && <div className="statistics-block__caption">{caption}</div>}
    </div>
}