import React from "react"
import FadeIn from "react-fade-in"

export function NavbarItemMobile(props) {
    const { onClick, onFadeInComplete, url, children, isExpanded, isClosing, isMainLink } = props

    const [fadeInDuration, setFadeInDuration] = React.useState(300)
    const [expandedChildren, setExpandedChildren] = React.useState([])

    React.useEffect(() => {
        setFadeInDuration(isClosing ? 175 : 300)
    }, [isClosing])

    function handleChildToggle(childId) {
        const _expandedChildren = Object.values(expandedChildren)
        const childIndex = _expandedChildren.indexOf(childId)
        if(childIndex == -1) {
            _expandedChildren.push(childId)
        }else{
            _expandedChildren.splice(childIndex, 1)
        }
        setExpandedChildren(_expandedChildren)
    }

    function isChildExpanded(childId) {
        return expandedChildren.includes(childId)
    }

    return (children?.length > 0
            // Item has child items
            ? <div className="navbar__item">
                <div className={"navbar__item-text" + (isMainLink ? " navbar__item-text--main-link" : "")} onClick={onClick}>
                    <a href={url} onClick={e => e.stopPropagation()}>{props.name}</a>
                    <i className="icon-chevron_down" style={{transform: !isClosing && isExpanded ? "rotate(180deg)" : "rotate(0deg)"}}></i>
                </div>
                {isExpanded && 
                    <FadeIn transitionDuration={fadeInDuration} visible={!isClosing} onComplete={onFadeInComplete} className="navbar__item-children">
                        {children?.map(child =>
                            <div className="navbar__item-children--child" key={props.id+"child"+child.id}>
                                <div className="navbar__item-children--child-wrapper">
                                    <a href={child.url}>{child.name}</a>
                                    {child.children && child.children.length > 0 && 
                                        <i className={"icon-chevron_down navbar__child2-toggle" + (isChildExpanded(child.id) ? " navbar__child2-toggle--expanded" : "")}
                                        onClick={() => handleChildToggle(child.id)}></i>
                                    }
                                </div>
                                {isChildExpanded(child.id) && 
                                <FadeIn transitionDuration={fadeInDuration}>
                                    {child.children?.map(childToChild => 
                                        <a href={childToChild.url} className="navbar__item-children--child2" key={props.id+"child"+child.id+"child"+childToChild.id}>{childToChild.name}</a>
                                    )}
                                </FadeIn>
                                }
                            </div>
                        )}
                    </FadeIn>
                }
              </div>

            // No child items, just render the link
            : <a className="navbar__item" href={url}>
                <div className={"navbar__item-text" + (isMainLink ? " navbar__item-text--main-link" : "")}>
                    {props.name}
                </div>
              </a>
    )
}