import ReactMarkdown from "react-markdown";

export function KundcaseListView(props) {
    const { items } = props
    const showTags = props.showFilters
    const itemsPerRow = 2
    const columnClass = "col-md-" + (12/itemsPerRow)
    
    const getElement = item => {
        return (
            <div className={[columnClass, "kundcase-list-view__item"].join(" ")}>
                {item.image && <div className="kundcase-list-view__item-image-wrapper">
                    <div className="card-tag-wrapper">
                        {showTags && item.tags && !!item.tags.length && 
                            item.tags.map((tag) =>( <div className={"card-tag card-tag--secondary"} key={tag.value || tag.Value}>{tag.label || tag.Label}</div>))
                        }
                    </div>
                    <a href={item.url}><img src={item.image} alt={item.title} loading="lazy" /></a>
                </div>}
                <div className="kundcase-list-view__item-body">
                    <a href={item.url}><h5 className="kundcase-list-view__item-title">{item.title}</h5></a>
                    <div className="kundcase-list-view__item-description"><ReactMarkdown>{item.description}</ReactMarkdown></div>
                    <a href={item.url} className="kundcase-list-view__item-read-more-link arrow-right">Läs mer</a>
                </div>
            </div>
        )
    }

    return <div className="list-view kundcase-list-view">
            {items && items.map(item => 
                getElement(item)  
            )}
         </div>
}