import * as FieldTypes from "./Fieldtypes";

const FIELD_TYPES = {
    "File upload": FieldTypes.FileUpload,
    "Checkbox": FieldTypes.Checkbox
}

export function Field(props) {
    const FieldType = FIELD_TYPES[props.fieldTypeName]
    const [error, setError] = React.useState()

    const handleOnError = msg => {
        setError(msg)
    }

    return FieldType 
        ? <>
            <FieldType {...props} onError={handleOnError}></FieldType>
            {error && <div className="alert alert--danger-light">{error}</div>}
        </>
        : ""
}