import React from 'react';
import { GRID_BREAKPOINTS } from '../Constants/GridBreakpoints.constants';
import { useWindowSize } from './useWindowSize';

export function useMaxWidthBreakpoint(breakpoint) {
    const [windowWidth] = useWindowSize()
    const [isBelowBreakpoint, setIsBelowBreakpoint] = React.useState(false)

    React.useEffect(() => {
      setIsBelowBreakpoint(windowWidth <= GRID_BREAKPOINTS[breakpoint])
    }, [windowWidth]);

    return isBelowBreakpoint;
}