import React from "react"
import { useWindowSize } from "../../Hooks/useWindowSize"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

export function FullscreenNavbarMenu(props) {
    const { items, footer, closeButtonClick } = props

    const getLinks = (item, depth = 0) => {
        if(depth == 0) {
            var sections = []
            for(var section of items) {
                sections.push(<div className="fullscreen-menu__section">{getLinks(section, depth+1)}</div>)
            }
            return sections
        }else{
            if(!item.children || item.children.length == 0) {
                return <a href={item.url} className={"depth-"+depth}>{item.name}</a>
            }

            let hasMoreChildrenThanMax = false
            return <>
                <a href={item.url} className={"depth-"+depth + " has-children"}>{item.name}</a>
                {item.children.map((child, i) => {
                    if(typeof item.maxNumChildren != 'undefined' && item.maxNumChildren > 0 ) {
                        if(item.maxNumChildren > i) {
                            return getLinks(child, depth+1)
                        }else{
                            hasMoreChildrenThanMax = true
                        }
                    }else{
                        return getLinks(child, depth+1)
                    }
                })}
                {hasMoreChildrenThanMax && item.showAllChildrenLinkText &&
                    <a href={item.url} className={"depth-"+(depth+1)}>{item.showAllChildrenLinkText}</a>
                }
            </>
        }
    }

    return (
        <div className="fullscreen-menu">
            <button onClick={() => closeButtonClick && closeButtonClick()}
                className="fullscreen-menu__close-button hamburger hamburger--3dx is-active" type="button">
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <div className="fullscreen-menu__content">
                <div className="fullscreen-menu__sections-wrapper">
                    <Masonry gutter="64px" columnsCount={3}>
                        {getLinks()}
                    </Masonry>
                </div>
                <div className="fullscreen-menu__footer">
                    <div className="fullscreen-menu__contact">
                        <a href={"tel:"+footer.phone}><i className="icon-phone"></i> {footer.phone}</a>
                        <a href={"mailto:"+footer.email}><i className="icon-email"></i> {footer.email}</a>
                    </div>
                    <div className="fullscreen-menu__social-media">
                        <a className="social-media-bubble" href={footer.socialMedia.facebook}><i className="icon-facebook"></i></a>
                        <a className="social-media-bubble" href={footer.socialMedia.instagram}><i className="icon-instagram"></i></a>
                        <a className="social-media-bubble" href={footer.socialMedia.linkedIn}><i className="icon-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}