import React from 'react';

const ListViewNoResults = () => {
    return (
        <div className='list-view__no-results-container'>
            <h2 className='no-results__title'>Inga resultat matchade din filtrering.</h2>
        </div>
    );
};

export default ListViewNoResults;