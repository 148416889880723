import React from "react"
import { useKeenSlider } from "keen-slider/react"
import { useWindowSize } from "../Hooks"
import { KeenSliderWrapper } from "./KeenSliderWrapper"
import { GRID_BREAKPOINTS } from "../Constants/GridBreakpoints.constants"

export function LogoSlider(props) {
    const { items }  = props
    const [windowWidth] = useWindowSize()
    const [slidesPerView, setSlidesPerView] = React.useState(5)

    React.useEffect(() => {
        if(windowWidth <= GRID_BREAKPOINTS.md)
            setSlidesPerView(3.1)
        else
            setSlidesPerView(4.1)
    }, [windowWidth])

    const getSlider = () => {
        if(windowWidth == 0 || windowWidth > GRID_BREAKPOINTS.mdLarge) {
            return <div className="featured-customers">
                {items.map((item, i) => 
                    <div className="featured-customers__customer-wrapper" key={i+item.name+"desktop"}>
                        <div className="featured-customers__customer" aria-label={item.name}>
                            <img src={item.imageUrl} alt={"Logo for " + item.name} />
                        </div>
                    </div>
                )}
            </div>
        }else{
            return <KeenSliderWrapper options={{
                slidesPerView: slidesPerView,
                mode: "snap"
            }} className="featured-customers--keen-slider">
                {items.map((item, i) => 
                    <div className="keen-slider__slide featured-customers__customer" key={i+item.name+"mobile"} aria-label={item.name}>
                        <img src={item.imageUrl} alt={"Logo for " + item.name} />
                    </div>
                )}
            </KeenSliderWrapper>
        }
    }

    return getSlider()
}