import { Label } from "../Label"

export function Checkbox(props) {
    var attributes = {}
    if(props.mandatory) {
        attributes["data-val"] = true
        attributes["data-val-requiredcb"] = props.requiredErrorMessage
    }

    const [checked, setChecked] = React.useState(props.values.includes("true") || props.values.includes(true) || props.values.includes("on"))

    React.useState(() => {
        if(!checked && props.mandatory)
            props.onError(props.requiredErrorMessage)
    }, [checked])

    return <>
        <input type="checkbox" name={props.name} id={props.id} value={true} 
                data-umb={props.id}
                {...attributes}
                checked={checked}
                onChange={() => setChecked(!checked)} />
        <Label fieldId={props.id} showIndicator={props.showIndicator} indicator={props.indicator}>
            {props.caption}
        </Label>
        <input type="hidden" name={props.name} value={checked} />
    </>
}