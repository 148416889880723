import React from "react";
import desktopVector from "../../images/block-vector-desktop.svg";
import mobileVector from "../../images/block-vector-mobile.svg";

export function TextWithLinks({ bodyText, linksTitle, links, person, title }) {
  return (
    <div className="text-with-links">
      <div className="text-with-links__content-wrapper">
        <div className="text-with-links__content">
          {title && <h1 className="text-with-links__title">{title}</h1>}
          {bodyText && (
            <div
              className="text-with-links__body-text"
              dangerouslySetInnerHTML={{ __html: bodyText }}
            ></div>
          )}
          <div className="text-with-links__person">
            <img
              className="text-with-links__person-img"
              src={person.imageUrl}
            />
            <div className="text-with-links__person-info">
              {person.name && (
                <div className="text-with-links__person-name">
                  {person.name}
                </div>
              )}
              {person.title && (
                <div className="text-with-links__person-title">
                  {person.title}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-with-links__links">
        {linksTitle && (
          <div className="text-with-links__links-title">
            {linksTitle}
            <img
              src={desktopVector}
              className="text-with-links__links-title-vector text-with-links__links-title-vector--desktop"
            />
            <img
              src={mobileVector}
              className="text-with-links__links-title-vector text-with-links__links-title-vector--mobile"
            />
          </div>
        )}
        <ul className="text-with-links__links-list">
          {!!links.length &&
            links.map((link) => (
              <li className="text-with-links__links-list-item">
                <a
                  className="text-with-links__links-list-anchor"
                  href={link.url}
                >
                  {link.title && link.title}
                  <img
                    src={link.imageUrl}
                    className="text-with-links__links-list-image"
                  />
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
