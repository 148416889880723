import React from "react"
import { NavbarItemMobile } from "./NavbarItemMobile"

const HAMBURGER_ANIMATION_CLASS = "squeeze"

export function NavbarMobile(props) {
    const { logoUrl, items, ctaButton, maxMenuItems } = props
    const [ isMenuVisible, setMenuVisible ] = React.useState(false)
    const [ activeMenu, setActiveMenu ] = React.useState(false)
    const [ isMenuClosing, setIsMenuClosing ] = React.useState(false)

    const onClickItem = item => {
        if(item.children.length > 0) {
            if(activeMenu != item.id) {
                setActiveMenu(item.id)
            }else{
                setIsMenuClosing(true)
            }
        }
    }

    const onFadeCompleted = itemId => {
        if(activeMenu == itemId && isMenuClosing) {
            setActiveMenu(undefined)
            setIsMenuClosing(false)
        }
    }
    
    React.useLayoutEffect(() => {
        // Remove prevent-scroll class from body when switching to mobile view
        var body = document.querySelector("body")
        if(body.classList.contains("prevent-scroll-mobile"))
            body.classList.remove("prevent-scroll-mobile")
    }, [])

    React.useEffect(() => {
        // Prevent scroll on body when menu is open
        var body = document.querySelector("body")
        if(isMenuVisible)
            body.classList.add("prevent-scroll-mobile")
        else
            body.classList.remove("prevent-scroll-mobile")
    }, [isMenuVisible])

    return (
        <nav className={["navbar", "navbar--mobile", isMenuVisible ? "navbar--expanded" : ""].join(" ")}>
            <a className="navbar__logo" href="/" aria-label="Return to homepage">
                <img src={logoUrl} width="100" height="32" alt="Motillo logo" />
            </a>
            <button onClick={() => setMenuVisible(!isMenuVisible)}
            className={["navbar__toggle-btn", "hamburger", "hamburger--" + HAMBURGER_ANIMATION_CLASS, isMenuVisible ? "is-active" : ""].join(" ")}
            type="button"
            aria-label="Navbar hamburger button">
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <div className="navbar__items-wrapper">
                {isMenuVisible && <div className="navbar__items">
                    {items.map((item, i) => 
                        <NavbarItemMobile {...item}
                            children={item.children}
                            key={item.id+"navitemmobile"}
                            onClick={() => onClickItem(item) }
                            isExpanded={activeMenu == item.id}
                            isClosing={isMenuClosing && activeMenu == item.id}
                            isMainLink={/*maxMenuItems > i*/true/*always true for now*/}
                            onFadeInComplete={() => onFadeCompleted(item.id)}>
                        </NavbarItemMobile>
                    )}
                </div>}
                {isMenuVisible && ctaButton.enabled && 
                    <div className="navbar__cta-button-wrapper">
                        <a className={"navbar__cta-button btn btn--"+ctaButton.mobileType+" btn--small"} href={ctaButton.url}>{ctaButton.caption}</a>
                    </div>
                }
            </div>
        </nav>
    )
}