import React from "react"

const RECENT_PAGES = "recentPages"
const MAX_PAGES = 5

export function RecentPagesTracker(page) {
    React.useEffect(() => {
        if(!localStorage) return

        let pages = JSON.parse(localStorage.getItem(RECENT_PAGES) ?? "[]")

        const dateNow = new Date()
        const idsToRemove = []
        pages.forEach(page => {
            // Remove pages that were visited 30 days ago, or later
            const pageDate = new Date(page.date)
            const timeDifferenceExpired = 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds
            if(dateNow.getTime() - pageDate.getTime() > timeDifferenceExpired)
                idsToRemove.push(page.id)
        })
        idsToRemove.forEach(id => pages.splice(pages.findIndex(page => page.id == id), 1))

        let currPageIndex = pages.findIndex(p => p.id == page.id)
        if(currPageIndex != -1) {
            pages[currPageIndex].date = page.date
        }else{
            pages.push(page)
        }

        // Sort by date, newest date first
        pages.sort((a, b) => new Date(b.date) - new Date(a.date))

        if(pages.length > MAX_PAGES) {
            // Remove the oldest page
            pages.splice(pages.length-1, 1)
        }

        localStorage.setItem(RECENT_PAGES, JSON.stringify(pages))
    }, [])

    return ""
}