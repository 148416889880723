import React from "react";

export function VideoPlayer({ videoUrl, autoplay, loop, controls, height, width, interactive, muted, imageMedia }) {

  const isYouTube = checkYouTubeVideo(videoUrl);
  const isVimeo = checkVimeoVideo(videoUrl);
  const giphyDirectUrl  = checkGiphyEmbed(videoUrl);
  const isMP4 = checkMP4Video(imageMedia?.image);

  const iframeStyle = {
    pointerEvents: interactive ? "auto" : "none",
  };

  return (
    <div className="player-wrapper">
      <div className="react-player">
        {isYouTube ? (
          <iframe
            className="youtube-video"
            height={height}
            width={width}
            style={iframeStyle}
            src={`https://www.youtube.com/embed/${getYouTubeVideoId(videoUrl)}?autoplay=${autoplay ? 1 : 0}&loop=${loop ? 1 : 0}&controls=${controls ? 1 : 0}&mute=${muted ? 1 : 0}&playlist=${getYouTubeVideoId(videoUrl)}&rel=0&modestbranding=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen"
          ></iframe>
        ) : isVimeo ? (
          <iframe
            height={height}
            width={width}
            style={iframeStyle}
            src={`https://player.vimeo.com/video/${getVimeoVideoId(videoUrl)}?autoplay=${autoplay ? 1 : 0}&loop=${loop ? 1 : 0}&controls=${controls ? 1 : 0}&muted=${muted ? 1 : 0}`}
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        ) : giphyDirectUrl ? (
            <iframe
            className="giphy-embed"
            height={height}
            width={width}
            style={iframeStyle}
            src={giphyDirectUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
        ) : isMP4 ? (
          <video
            className="mp4-video"
            height={height}
            width={width}
            style={iframeStyle}
            autoPlay={autoplay}
            loop={loop}
            controls={controls}
            muted={muted}
          >
            <source src={imageMedia?.image} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>Unsupported video source</p>
        )}
      </div>
    </div>
  );
}

function checkYouTubeVideo(url) {
  return /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/.test(url);
}

function getYouTubeVideoId(url) {
  const match = url.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
  return match ? match[1] : null;
}

function checkVimeoVideo(url) {
  return /(?:vimeo\.com\/(?:video\/)?(\d+))/.test(url);
}

function getVimeoVideoId(url) {
  const match = url.match(/(?:vimeo\.com\/(?:video\/)?(\d+))$/);
  return match ? match[1] : null;
}

function checkGiphyEmbed(embedCode) {
  const srcRegex = /<iframe\s+src="([^"]+)"/;
  const match = embedCode.match(srcRegex);

  return match ? match[1] : null;
}

function checkMP4Video(url) {
  return /\.(mp4)$/i.test(url);
}
