import React from "react"
import { FullscreenNavbarMenu } from "./FullscreenNavbarMenu"
import { MegaMenu } from "./MegaMenu"
import { NavbarItem } from "./NavbarItem"

const FULLSCREEN_MENU = "fullscreen-menu"
const FULLSCREEN_MENU_BTN_TEXT = "Mer"
const FULLSCREEN_MENU_BTN_ICON = "menu"

export function NavbarDesktop(props) {
    const { logoUrl, items, ctaButton, maxMenuItems, footer } = props
    const [activeMenu, setActiveMenu] = React.useState(undefined)
    const [mouseOverBtn, setMouseOverBtn] = React.useState(false)
    const [mouseOverMenu, setMouseOverMenu] = React.useState(false)

    const handleMouseEnterBtn = itemId => {
        setMouseOverBtn(true)
        setActiveMenu(getMenuById(itemId))
    }

    const handleMouseLeaveBtn = () => {
        // Delay to stop hover bug
        setTimeout(() => {
            setMouseOverBtn(false)
        }, 1)
    }

    const getMenuById = id => items.find(menu => menu.id == id)

    React.useEffect(() => {
        var mainElem = document.querySelector("main")
        // If mouse is not over the button or over the mega menu
        if (!mouseOverBtn && !mouseOverMenu) {
            // Close it
            setActiveMenu(undefined)
            // Remove overlay
            if (mainElem.classList.contains("has-overlay"))
                mainElem.classList.remove("has-overlay")
        } else {
            // Otherwise, menu is visible, show dark overlay
            if (!mainElem.classList.contains("has-overlay"))
                mainElem.classList.add("has-overlay")
        }
    }, [mouseOverBtn, mouseOverMenu])

    React.useEffect(() => {
        var bodyElem = document.querySelector("body")
        // If fullscreen menu is visible, we want to remove the scrollbar using the "prevent-scroll" css class
        if (activeMenu === FULLSCREEN_MENU) {
            if (!bodyElem.classList.contains("prevent-scroll"))
                bodyElem.classList.add("prevent-scroll")
        } else {
            bodyElem.classList.remove("prevent-scroll")
        }
    }, [activeMenu])

    return (
        <React.Fragment>
            <nav className="navbar navbar--desktop">
                <div className="container">
                    <a className="navbar__logo" href="/" aria-label="Return to homepage">
                        <img src={logoUrl} width="140" height="45" alt="Motillo logo" />
                    </a>
                    <div className="navbar__items">
                        {/* Top level menu items */}
                        {items.map((item, index) =>
                        (index < maxMenuItems &&
                            <NavbarItem {...item}
                                key={item.id + "navitem"}
                                isActive={activeMenu && activeMenu.id === item.id}
                                onMouseEnter={() => item.children?.length > 0 && item.showMegaMenu && handleMouseEnterBtn(item.id)}
                                onMouseLeave={() => item.children?.length > 0 && item.showMegaMenu && handleMouseLeaveBtn()}>
                            </NavbarItem>)
                        )}
                    </div>

                    {/* CTA Button */}
                    {ctaButton.enabled && <a className={"navbar__cta-button btn btn--" + ctaButton.desktopType + " btn--small"} href={ctaButton.url}>{ctaButton.caption}</a>}
                </div>
            </nav>
            <div className="navbar__menu-objects">
                {items.map((item, index) =>
                    <div className={activeMenu && activeMenu.id === item.id ? "container navbar__menu-container navbar__menu-container--active" : "container navbar__menu-container"}>
                        <MegaMenu
                            menu={item}
                            key={"navbar-megamenu"}
                            isVisible={true}
                            onMouseEnter={() => setMouseOverMenu(true)}
                            onMouseLeave={() => setMouseOverMenu(false)}>
                        </MegaMenu>
                    </div>
                )}
            </div>
            <div className={activeMenu === FULLSCREEN_MENU ? "fullscreen-menu-container fullscreen-menu-container--active" : "fullscreen-menu-container"}>
                <FullscreenNavbarMenu items={items} footer={footer} closeButtonClick={() => setActiveMenu(undefined)}></FullscreenNavbarMenu>
            </div>
        </React.Fragment>
    )
}