import React from "react"

export function NavbarItem(props) {
    const [ mouseOver, setMouseOver ] = React.useState(false)
    const { onClick, onMouseEnter, onMouseLeave, url, isActive } = props

    return (
        <a className={["btn", "btn--text", "navbar__item", isActive || mouseOver ? "is-active" : ""].join(" ")}
            href={url} 
            onClick={onClick}
            onMouseEnter={() => { setMouseOver(true); onMouseEnter && onMouseEnter(); } }
            onMouseLeave={() => { setMouseOver(false); onMouseLeave && onMouseLeave(); }}>
                <span className="navbar__item-text">
                    {props.name}
                    {props.icon && <i className={"icon-"+props.icon}></i>}
                </span>
        </a>
    )
}