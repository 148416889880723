export function StaffCTA(props) {
    const { staff, caption, image } = props

    return (
        <div className="staff-cta">
            {image && <a href={staff.url} className="staff-cta__image-wrapper"><img src={image} alt="Employee image" /></a>}
            <div className="staff-cta__body">
                <div className="staff-cta__caption">{caption}</div>
                <div className="staff-cta__staff-info">
                    <a href={staff.url} className="staff-cta__name">
                        <div>{staff.name}</div>
                        {staff.title && <div className="staff-cta__name--title">{staff.title}</div>}
                    </a>
                    <div className="staff-cta__contact">
                        {staff.email && <a href={"mailto:"+staff.email}><i className="icon-email"></i> {staff.email}</a>}
                        {staff.phone && <a href={"tel:"+staff.phone}><i className="icon-phone"></i> {staff.phone}</a>}
                    </div>
                </div>
            </div>
        </div>
    )
}