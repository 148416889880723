import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { GRID_BREAKPOINTS } from "../Constants/GridBreakpoints.constants"
import { useMaxWidthBreakpoint } from "../Hooks/useMaxWidthBreakpoint"

export function EmployeesList(props) {
    const { employees } = props
    const isMobile = useMaxWidthBreakpoint("md")

    const getEmployeeElement = employee => <div className="employees-list__employee-item" key={employee.id}>
        <div className="employee__item-content">
            <div><a href={employee.url}><img className="employee__image" src={employee.imageUrl} loading="lazy" /></a></div>
            <div className="employee__details">
                <a href={employee.url}><h4 className="employee__name">{employee.name}</h4></a>
                <div className="employee__title">{employee.title}</div>
                <div className="employee__contact-links">
                    {employee.phone && <a href={"tel:"+employee.phone}>{employee.phone}</a>}
                    {employee.email && <a href={"mailto:"+employee.email}>{employee.email}</a>}
                </div>
            </div>
        </div>
    </div>

    // const gridGutter = isMobile ? 40 : 128
    // const bootstrapGutter = 32
    // var columnBreakpoints = {}
    // columnBreakpoints[GRID_BREAKPOINTS.xs] = 1 // 1 column below MD
    // columnBreakpoints[GRID_BREAKPOINTS.md - bootstrapGutter - 1] = 2 // 2 columns at MD breakpoint and up (minus bootstrap grid gutter)

    // return <ResponsiveMasonry columnsCountBreakPoints={columnBreakpoints}>
    //     <Masonry gutter={gridGutter+"px"} className="employees-list">
    //         {employees && employees.map((employee, i) => 
    //             getEmployeeElement(employee, i)
    //         )}
    //     </Masonry>
    // </ResponsiveMasonry>
    // return <div className="row"><div className="employees-list">
    //     {employees && employees.map((employee, i) => 
    //         getEmployeeElement(employee, i)
    //     )}
    // </div></div>
    const getEmployees = left => employees.map((employee, i) => i % 2 == (left ? 0 : 1) && getEmployeeElement(employee))
    return (!isMobile 
        ? <div className="row">
            <div className="col-6 employees-list employees-list--left">{getEmployees(true)}</div>
            <div className="col-6 employees-list employees-list--right">{getEmployees(false)}</div>
        </div>
    : <div className="employees-list">
        {employees.map(employee => getEmployeeElement(employee))}
    </div>
    )
}