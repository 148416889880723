import React, { useEffect, useMemo } from "react"
import { CardFeed, ListView, KundcaseListView } from "../Components/ListViews"
import { LIST_VIEW_TYPES } from "../Constants/ListViewTypes.Constants"
import { ProgressBarContainer } from "./ProgressBar.container"
import ListViewFilterBar from "../Components/ListViews/ListViewFilterBar"
import ListViewNoResults from "../Components/ListViews/ListViewNoResults"

export function ListViewContainer(props) {
    const { viewType, contain, loadMoreEnabled, loadMoreButtonText, parentIds,
            loadMoreItemsUrl, totalNumberOfItems, loadMoreCount, showProgressBar,
            showNumItems, orderBy, tags, maxItems, showFilters } = props
    const [items, setItems] = React.useState(props.items)
    const [isLoadingMore, setIsLoadingMore] = React.useState(false)
    const [showLoadMoreButton, setShowLoadMoreButton] = React.useState(loadMoreEnabled && totalNumberOfItems > items.length)
    const [filters, setFilters] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [itemsLeft, setItemsLeft] = React.useState(totalNumberOfItems - props.items.length);

    const totalItemsLeft = useMemo(() => filters ? itemsLeft + items.length : totalNumberOfItems, [items]);

    const getCommaString = (list, property) => list.map(itm => itm[property]).join(",")

    const handleLoadMore = (isFilter) => {
        var url = `${loadMoreItemsUrl}` +
                        `?currentIds=${isFilter ? "" : getCommaString(items, "id")}` +
                        `&parentIds=${parentIds.join(",")}` +
                        `&maxItems=${isFilter ? maxItems : loadMoreCount}`+
                        `&orderBy=${orderBy}`+
                        (!!filters.length ? `&tags=${filters.join(',')}` : "");
        setIsLoadingMore(true)
        fetch(url, {
            headers: new Headers({
                'Content-Type': "application/json"
            })
        })
        .then(res => {
            if(res.ok)
                return res.json()
            else
                throw res
        })
        .then(data => {
            setItemsLeft(data.meta.itemsLeft)
            setItems(isFilter ? data.items : [...items, ...data.items])
            setShowLoadMoreButton(data.meta.itemsLeft > 0)
            setIsLoadingMore(false)
        })
        .catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        const getTagsFromURL = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const tagsParam = urlParams.getAll('tag');
    
            if (!!tagsParam.length) {
                setFilters(tagsParam);
            }
        };
    
        getTagsFromURL();
        setIsLoaded(true);
    }, []);
    

    useEffect(() => {
        if(!isLoaded){
            return;
        }
        const queryParams = new URLSearchParams();

        updateQuery(queryParams);
    
        handleLoadMore(true);
    }, [filters])

    useEffect(() => {
        if(!isLoaded){
            return;
        }
        const queryParams = new URLSearchParams();

        updateQuery(queryParams);
    
    }, [items])

    const updateQuery = (queryParams) => {
        if(!!filters.length){
            filters.forEach((filter) => {
                queryParams.append('tag', filter)
            })
        }

        if(!!items.length){
            queryParams.append('items', items.length);
        }

        const updatedUrl = `${window.location.origin}${window.location.pathname}${queryParams.toString() ? '?' : ''}${queryParams}`;
        window.history.pushState({}, '', updatedUrl); 

        return queryParams;
    }

    var listElement

    switch(viewType) {
        case LIST_VIEW_TYPES.default:
        case LIST_VIEW_TYPES.defaultSmallImg:
        case LIST_VIEW_TYPES.defaultSmallImgAlt: {
            listElement = (
                contain
                ? <div className="container">
                    <ListView {...props} items={items}></ListView>
                  </div>
                : <ListView {...props} items={items}></ListView>
            )
            break
        }

        case LIST_VIEW_TYPES.cardFeed:
        case LIST_VIEW_TYPES.cardFeedSmallImage:
        case LIST_VIEW_TYPES.cardFeedLink: {
            listElement = (contain
                ? <div className="container">
                    <CardFeed {...props} isVisible={true} items={items}></CardFeed>
                  </div>
                : <CardFeed {...props} isVisible={true} items={items}></CardFeed>
            )
            break
        }

        case LIST_VIEW_TYPES.kundcase:
            listElement = <KundcaseListView {...props} items={items}></KundcaseListView>
            break

        default: {
            return "INVALID_LIST_VIEW_TYPE"
        }
    }

    return <>
        {showFilters && tags && tags.length > 0 && <ListViewFilterBar tags={tags} filters={filters} setFilters={setFilters} />}
        {listElement}
        {!items.length && <ListViewNoResults />}
        {(showLoadMoreButton || showProgressBar || showNumItems) &&
         <div className="row" style={{marginTop: "32px"}}>
            <div className="col-12 align-items-center">
                {!!items.length && showNumItems &&
                    <p className="text-small" style={{ marginTop: 0, marginBottom: "8px" }}>
                        Visar {items.length} av {totalItemsLeft} {totalItemsLeft == 1 ? props.itemNameSingular?.toLowerCase() : props.itemNamePlural?.toLowerCase()}
                    </p>
                }
                {!!items.length && loadMoreEnabled && showProgressBar && <ProgressBarContainer endValue={totalItemsLeft} value={items.length} />}
                {showLoadMoreButton &&
                    <button className="btn btn--outline btn--large"
                      style={{ marginTop: "18px" }}
                      onClick={() => handleLoadMore()}
                      disabled={isLoadingMore}>
                        {!isLoadingMore
                            ? loadMoreButtonText ?? "Ladda mer"
                            : "Laddar..."}
                    </button>
                }
            </div>
          </div>
        }
    </>
}