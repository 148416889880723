import ReactMarkdown from "react-markdown"
import React from "react"
import { LIST_VIEW_TYPES } from "../../Constants/ListViewTypes.Constants"

export function ListViewItem(props) {
    const { title, description, url, image, viewType, icon, isPublished } = props
    const imagePosition = viewType == LIST_VIEW_TYPES.defaultSmallImgAlt ? "left" : null
    const isSmallImage =  viewType == LIST_VIEW_TYPES.defaultSmallImg || viewType == LIST_VIEW_TYPES.defaultSmallImgAlt

    return (isPublished &&
        <a className={"list-view__item list-view__item--image-"+imagePosition} href={url} style={{flexDirection: !imagePosition ? "column" : "row"}}>
            {image && <div className={"list-view__item__image " + (isSmallImage ? "list-view__item__image--small" : "")} style={{alignSelf: imagePosition}}>
                <img src={image} alt={title} width="48" height="auto" />
            </div>}
            {!image && icon && <div className={"list-view__item__image list-view__item__image--small list-view__item__image--icon"} style={{alignSelf: imagePosition}}>
                <i className={icon}></i>
            </div>}
            <div className="list-view__item__body">
                <h5 className="list-view__item__title">{title}</h5>
                <div className="list-view__item__description"><ReactMarkdown>{description}</ReactMarkdown></div>
            </div>
        </a>
    )
}