import { ImageGallery } from "./ImageGallery"
import React from "react"

export function ClickableImage(props) {
    const { src, caption, alt } = props
    const [isOpen, setIsOpen] = React.useState(false)

    const imageGalleryImages = [{ src: src, caption: caption }]

    return <>
            <img src={src} className="fullwidth" loading="lazy" alt={caption} /*onClick={() => setIsOpen(true) }*/  />
            {caption && 
                <div className="media-block-image__caption">{caption}</div>
            }
            {/* <ImageGallery 
                images={imageGalleryImages} 
                isOpen={isOpen} 
                onClose={() => setIsOpen(false)}
                currentImage={imageGalleryImages[0]}
            /> */}
        </>
}