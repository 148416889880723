import React from 'react';
import { createStore } from 'redux';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import { ServerStyleSheet } from 'styled-components';

import * as allComponents from './Components';
import * as allContainers from './Containers';

// import reducers from "./Reducers/CombinedReducers";
// import store from './store';

global.React = React;
// global.Store = store
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Styled = { ServerStyleSheet };

global.Components = allComponents;
global.Containers = allContainers;
