import React from "react";
const ListFilterBar = ({ tags, filters, setFilters }) => {
  const handleFilterClick = (e, clickedFilter) => {
    e.target.blur();

    filters.includes(clickedFilter)
      ? setFilters(filters.filter((filter) => filter != clickedFilter))
      : setFilters([...filters, clickedFilter]);
  };

  const handleClearClick = (e) => {
    setFilters("");
  };

  return (
    <>
      <button
        className={`filter-bar__clear-btn-mobile ${
          !filters.length ? "filter-bar__clear-btn--hidden" : ""
        }`}
        onClick={(e) => handleClearClick(e)}
      >
        Rensa filter
      </button>
      <div className="filter-bar__container-effect">
        <div className="filter-bar__container">
          {!!tags.length && tags.map((tag) => (
            <button
              key={tag.value}
              className={`btn btn--small ${
                filters.includes(tag.value) ? "btn--primary" : "btn--white-filled"
              }`}
              onClick={(e) => handleFilterClick(e, tag.value)}
            >
              {tag.label}
            </button>
          ))}
          <button
            className={`filter-bar__clear-btn filter-bar__clear-btn-desktop ${
              !filters.length ? "filter-bar__clear-btn--hidden" : ""
            }`}
            onClick={(e) => handleClearClick(e)}
          >
            <i className="icon-close"></i>
          </button>
        </div>
      </div>
    </>
  );
};
export default ListFilterBar;
