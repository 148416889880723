import { ImageGallery } from "./ImageGallery"
import React from "react"

export function OverlappingImage(props) {
    const { largeImageUrl, smallImageUrl, caption, expandable } = props
    var { smallImagePosition } = props
    if(!smallImagePosition) {
        smallImagePosition = "bottom-left"
    }
    const [isOpen, setIsOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState()

    var mainImageWrapperClassList = [
        "overlapping-image__wrapper"
    ]

    if(smallImageUrl) {
        mainImageWrapperClassList.push("overlapping-image__wrapper--has-small-image")
        mainImageWrapperClassList.push("overlapping-image__wrapper--has-small-image--" + smallImagePosition)
    }

    const imageGalleryImages = [
        {
            src: largeImageUrl,
            caption: caption
        },
        {
            src: smallImageUrl,
            caption: caption
        }
    ]

    const handleClick = imgSrc => {
        if(expandable) {
            var image = imageGalleryImages.find(img => img.src == imgSrc)
            setCurrentImage(image)
            setIsOpen(true)
        }
    }

    return <>
        <div className={mainImageWrapperClassList.join(" ")} style={{paddingBottom: smallImagePosition.indexOf("bottom") == -1 ? "24px" : 0}}>
            <div className="overlapping-image__image-wrapper">
                <img src={largeImageUrl} className="overlapping-image__image" alt={caption} onClick={() => handleClick(largeImageUrl)}/>
                {smallImageUrl &&
                    <img src={smallImageUrl} className="overlapping-image__small-image" alt={caption} onClick={() => handleClick(smallImageUrl)} />
                }
            </div>
            {/* <div className="overlapping-image__caption">{caption}</div> */}
        </div>
    </>
}