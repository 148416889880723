import React from "react"

export function FileUpload(props) {
    var [files, setFiles] = React.useState(props.values || [])

    const fileInputRef = React.createRef()

    const isValid = () => props.mandatory && files && files.length > 0 || !props.mandatory
    const getFileName = filePath => {
        var pathSplit = filePath.split("/")
        return pathSplit[pathSplit.length-1]
    }
    const onSelectFilesClick = e => {
        fileInputRef.current.click()
    }
    const onFileSelect = e => {
        let files = []
        for(var file of e?.target?.files)
            files.push(file.name)
        setFiles(files)
    }
    const clearFiles = e => {
        e.stopPropagation()
        setFiles([])
    }

    return (
        <div className="motillo-form__file-upload" onClick={onSelectFilesClick}>
            <i className="icon-cloud-upload"></i>
            {files && files.length > 0 &&
                <div className="motillo-form__file-upload-list">
                    {files.map((file, i) => 
                        <div className="motillo-form__file-upload-file" key={file+i}>
                            {getFileName(file)}
                        </div>
                    )}
                </div>
            }
            {(!files || files.length == 0) &&
                <div className="motillo-form__file-upload-title">{props.caption}{props.mandatory && "*"}</div>
            }
            {files && files.length > 0 &&
                <button className="btn btn--primary btn--small" style={{marginTop: "16px"}} onClick={clearFiles}>Rensa</button>
            }

            <input type="file" name={props.name} id={props.id} ref={fileInputRef}
                onChange={onFileSelect}
                data-umb={props.id}
                data-val={isValid() ? null : true}
                data-val-required={isValid() ? null : props.requiredErrorMessage }
                multiple
                hidden />
        </div>
    )
}