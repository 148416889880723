export function LinkCard(props) {
    const { title, id, isVisible, className } = props
    
    var { url } = props
    if(typeof url == "object")
        url = url.target.url
    
    return (isVisible !== false && 
        <div className={"card card__link-card card__link-card--primary " + (className ?? "")}  key={id}>
            <div className="card__body">
                <a href={url} className="card__title">
                    {title}
                </a>
                <a href={url} className="card__arrow" aria-label={title}>
                    <i className="icon-arrow_right icon-type--primary"></i>
                </a>
            </div>
        </div>
    )
}