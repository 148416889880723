import FadeIn from "react-fade-in"
import { Card } from "../ListViews/Card"
import React from "react"

export function MegaMenu(props) {
    const { isVisible, onMouseEnter, onMouseLeave, menu } = props
    const cards = menu.cards
    const [sections, setSections] = React.useState()

    const generateSections = () => {
        const sections = []
        const independentLinks = []

        return new Promise(resolve => {
            menu.children.map((section, index) => {
                // If max num children is reached, do not generate this item
                if(menu.maxNumChildren > 0 && index >= menu.maxNumChildren) return
                
                // If this "section" (menu item) has any children, generate them
                if(section?.children?.length > 0) {
                    sections.push(
                        <div className="mega-menu__section" key={section.id+"section"}>
                            {section.imageUrl && 
                                <div className="mega-menu__image-wrapper">
                                    <img src={section.imageUrl} />
                                </div>
                            }
                            
                            {/* If the section has any children, add that class */}
                            {section.children.length 
                                ? <a href={section.url} key={section.id+section.name+"sectiontitle"} className="mega-menu__section-link mega-menu__section-link--has-children">{section.name}</a>
                                : <a href={section.url} key={section.id+section.name+"item"} className="mega-menu__section-link ">{section.name}</a>
                            }
    
                            {section.children.map((child, index) =>
                                // Limit number of children to display if the value is set
                                ((section.maxNumChildren == 0 || index < section.maxNumChildren) &&
                                    <a href={child.url} key={child.name+child.id+"child"} className={"mega-menu__section-link" + (child.imageUrl ? " mega-menu__section-link--has-image" : "")}>
                                        {child.imageUrl && 
                                            <div className="mega-menu__image-wrapper">
                                                <img src={child.imageUrl} />
                                            </div>
                                        }
                                        {child.name}
                                    </a>
                                )
                            )}
                            
                            {/* If limit number of children, display a link at the bottom with the specified text, if any (Such as "Show all articles" etc.) */}
                            {section.children.length > section.maxNumChildren && section.showAllChildrenLinkText &&
                                <a href={section.url} key={section.id+"show-all"} className="mega-menu__section-link">
                                    {section.showAllChildrenLinkText}
                                </a>
                            }
                        </div>
                    )
                }else{
                    // If no children, this menu item is independentant and should be displayed separately
                    independentLinks.push(
                        <a href={section.url} className="mega-menu__section-link" key={section.name+section.id+"independent"}>
                            {section.name}
                        </a>
                    )
                }
            })
    
            // Add each independent link to their own section
            sections.push(
                <div className="mega-menu__section mega-menu__section--independent" key="independet-section">
                    {independentLinks}
                </div>
            )
    
            resolve(sections)
        })
    }

    React.useEffect(() => {
        generateSections().then(s => setSections(s))
    }, [])

    return (isVisible &&
        <div className="mega-menu__overlay">
            <FadeIn transitionDuration="200">
                <div className="mega-menu" id={menu.id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <div className="mega-menu__sections">
                        {sections}
                    </div>
                    {cards && cards.length > 0 &&
                        <div className="mega-menu__cards">
                            {cards.map(card => <Card title={card.name} url={card.url}></Card>)}
                        </div>
                    }
                </div>
            </FadeIn>
        </div>
    )
}