import { NavbarDesktop, NavbarMobile } from "../Components"
import { GRID_BREAKPOINTS } from "../Constants/GridBreakpoints.constants";
import { useWindowSize } from "../Hooks";

export function NavbarContainer(props) {
    const { desktopLogoUrl, mobileLogoUrl, menuItems, ctaButton, maxMenuItems, footer } = props
    const [ viewportWidth ] = useWindowSize()

    if(viewportWidth > GRID_BREAKPOINTS.mdLarge) {
        // Desktop navbar
        return (
            <NavbarDesktop items={menuItems} logoUrl={desktopLogoUrl} ctaButton={ctaButton} maxMenuItems={maxMenuItems} footer={footer}></NavbarDesktop>
        )
    }else{
        // Mobile navbar
        return (
            <NavbarMobile items={menuItems} logoUrl={mobileLogoUrl} maxMenuItems={maxMenuItems} ctaButton={ctaButton}></NavbarMobile>
        )
    }
}