import React from "react"
import ReactMarkdown from "react-markdown"

const IMAGE_WIDTH = 800

export function Card(props) {
    const { title, recommendedOnPages, id, currentPage, image, pageType, description, smallImage, isVisible, className, tags} = props
    const [imageLoaded, setImageLoaded] = React.useState(false)
    var { url } = props
    url = typeof url == "string" ? url : url?.target?.url
    
    return (isVisible !== false && 
        <div className={[
            "card", 
            smallImage ? "card--small-image" : "", 
            !image ? "card--no-image" : "", 
            className ?? ""
          ].join(" ")} key={id}>
            
            {image && 
                <a className={"card__image-wrapper" + (!imageLoaded ? " card__image-wrapper--loading" : "")} href={url} aria-label={"Link to " + title}>
                    <img src={image} alt={title} width="48" height="auto" onLoad={() => setImageLoaded(true)} />
                </a>
            }
            <div className="card-tag-wrapper">
                {recommendedOnPages && recommendedOnPages.indexOf(currentPage) != -1 && 
                    <div className="card-tag card-tag--primary card-tag--recommended">
                        Rekommenderat
                    </div>
                }
                {tags && !!tags.length && 
                    tags.map((tag) =>( <div className={"card-tag card-tag--secondary"} key={tag.value || tag.Value}>{tag.label || tag.Label}</div>))
                }
            </div>
            <div className="card__body">
                <a href={url} className="unset-color">
                    <h5 className="card__title">{title}</h5>
                </a>
                <div className="card__text" dangerouslySetInnerHTML={{__html: description}}></div>
            </div>
            <a className="card__read-more arrow-right" href={url} aria-label={"Link to " + title}>Läs mer</a>
        </div>
    )
}