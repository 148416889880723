import React, { useEffect } from "react"
import { useKeenSlider } from "keen-slider/react"

const animation = { duration: 15000, easing: (t) => t }

export function LogoCarousel(props) {
    const { items, backgroundColor } = props

    const bgColor = backgroundColor != null ? backgroundColor : "fff";

    console.log(backgroundColor)
    const [sliderRef, sliderInstance] = useKeenSlider({     
        loop: true,
        renderMode: "performance",
        drag: true,
        breakpoints: {
            '(max-width: 500px)': {
                slides: {
                    perView: 4,
                    spacing: 40,
                },
                created(s) {
                    s.moveToIdx(8, true, animation)
                },
                updated(s) {
                s.moveToIdx(s.track.details.abs + 8, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 8, true, animation)
                },
            },
        },
        slides: {
            perView: 5,
            spacing: 60,
        },
        created(s) {
            s.moveToIdx(5, true, animation)
        },
        updated(s) {
        s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
    })

    useEffect(() => {
        sliderInstance?.current?.update()
    }, [sliderInstance])

    const getSlider = () => {
        return (
        <>
            <div ref={sliderRef} className="logo-carousel--keen-slider">
            <div className="logo-carousel--barrier">
                {items.map((item) => 
                <div className="keen-slider__slide logo-carousel--logo" key={item.id} aria-label={item.media?.name} >
                    <a href={item.link?.target?.url}>
                        <img src={item.media?.image} alt={"Logo for " + item.media?.name} />
                    </a>
                </div>
                )}
            </div>
        </div>

        <style>{`
            .logo-carousel--barrier {
                &::before {
                    left: 0;
                    background: linear-gradient(to right, #${bgColor} 50%, rgba(255, 255, 255, 0) 100%);
                }
                &::after {
                    right: 0;
                    background: linear-gradient(to left, #${bgColor} 50%, rgba(255, 255, 255, 0) 100%);
                } 
            `}</style>
        </>
        )
    }

    return getSlider()
}