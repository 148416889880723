import React from 'react';
import { useKeenSlider } from "keen-slider/react";
import { useWindowSize } from '../Hooks';
import { GRID_BREAKPOINTS } from "../Constants/GridBreakpoints.constants"


export function KundcaseSlider(props) {
    const { title, body, items } = props

    const [slidesPerView, setSlidesPerView] = React.useState(3)
    const [canSlideBack, setCanSlideBack] = React.useState(false)
    const [canSlideForward, setCanSlideForward] = React.useState(true)
    const [width] = useWindowSize()

    // Generate the keen-slider
    const [sliderRef, slider] = useKeenSlider({
        slides: {
            perView: slidesPerView,
            spacing: 32,
        },
        defaultAnimation: {
            duration: 1000,
        },
        dragSpeed: .8,
        slideChanged(_slider) {
            let slideCount = _slider.size
            let currSlide = _slider.track.details.rel
            let isNegativeSlide = _slider.track.details.abs < 0
            let maxIndex = _slider.track.details.maxIdx

            // Is between first and last possible slide?
            if(currSlide != maxIndex) {
                setCanSlideForward(true)
            }else { // make sure not negative
                setCanSlideForward(false)
            }

            // Not first slide, and the slider count is greater than one
            if(currSlide > 0 && slideCount > 1 && !isNegativeSlide) {
                setCanSlideBack(true)
            }else{
                setCanSlideBack(false)
            }
        }
    })

    // Change number of slides based on screen size
    React.useEffect(() => {
        if(width >= GRID_BREAKPOINTS.md && width < GRID_BREAKPOINTS.mdLarge) {
            // Between MD and MD Large
            setSlidesPerView(2.1)
        }else if(width < GRID_BREAKPOINTS.md) {
            // MD and down
            setSlidesPerView(1.1)
        }else if(slidesPerView) {
            // LG and up
            setSlidesPerView(3)
        }
    }, [width])

    React.useEffect(() => {
        // Find the parent div closest to the "main" tag, then apply "overflow-x: hidden" to disable x-scroll but still show
        // the slides to the side
        if(sliderRef && sliderRef.current) {
            var currParent = sliderRef.current.parentElement
            // Loop until we find the parent div closest to main, aka. the row of the whole grid editor
            while(currParent.parentElement.tagName.toLowerCase() != "main" && currParent.parentElement) {
                currParent = currParent.parentElement
            }
            // Set overflow-x to "hidden" on the whole row
            currParent.style.overflowX = "hidden"
        }
    }, [sliderRef])

    
    React.useEffect(() => {
        require("keen-slider/keen-slider.min.css");
    }, [])

    var slideItems = []
    for(var item of items) {
        slideItems.push(
            <a className="keen-slider__slide" href={item.link?.url ?? item.link?.target?.url} key={item.id}>
                <div className="kundcase-slider__slide" style={{"backgroundImage": "url("+item.media?.image+")"}}>
                    {/* TODO Set color of title based on color of background image */}
                    <div className="kundcase-slider__title-wrapper">
                        <h4 style={{color: "white"}}>{item.title}</h4>
                    </div>
                </div>
            </a>
        )
    }

    return (
        <div className="slider-wrapper">
            <div className="slider-wrapper__title">
                    <h2>{ title }</h2>
                </div>
            <div className="slider-wrapper__header">
                <div className="slider-wrapper__text">
                    <p>{ body }</p>
                </div>
                <div className="slider-wrapper__header-buttons d-md-block d-none">
                    {slider && (
                        <div className="slider-wrapper__arrows">
                            <i className="icon-arrow_left sliding-arrow" onClick={e => e.stopPropagation() || slider.current.prev() } disabled={!canSlideBack}></i>
                            <i className="icon-arrow_right sliding-arrow" onClick={e => e.stopPropagation() || slider.current.next() } disabled={!canSlideForward}></i>
                        </div>
                    )}
                </div>
            </div>
            <div ref={sliderRef} className="keen-slider kundcase-slider">
                {slideItems}
            </div>
        </div>
    );
}