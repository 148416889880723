import { Fieldset } from "../Components/UmbracoForm/Fieldset"

export function UmbracoFormContainer(props) {
    const { form, isMultiPage, isLastPage, isFirstPage, cssClass } = props
    const [currentPage, setCurrentPage] = React.useState(form.currentPage)

    return <div className={[form.cssClass, cssClass].join(" ")}>
        {currentPage.fieldsets.map(fs => 
            <Fieldset {...fs}
                formClassName={cssClass}
                showIndicator={props.showIndicator}
                indicator={props.indicator}
                key={fs.id}>
            </Fieldset>)}
    </div>
}