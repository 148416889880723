import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { ListViewItem } from "./ListViewItem"

export function ListView(props) {
    const { currentPage, viewType, items, numItemsEachLoad, columns, forceSameHeight, imagePosition } = props
    var textColorClass = "list-view--" + (props.textColor ?? "light")

    const getElement = item => {
        if(!item.isPublished) return null
        
        return <ListViewItem {...item} viewType={viewType}></ListViewItem>
    }

    return (forceSameHeight 
        ? <div className={"list-view list-view--same-height " + textColorClass}>
            {items && items.map(item => 
                getElement(item)  
            )}
         </div>
        : <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
            <Masonry className={"list-view list-view--masonry " + textColorClass} gutter="32px">
                {items && items.map(item => 
                    getElement(item)  
                )}
            </Masonry>
         </ResponsiveMasonry>
    )
}