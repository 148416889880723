import { Field } from "./Field"

export function Fieldset(props) {
    const { caption, containers, id, formClassName } = props

    return <fieldset className={formClassName + "__fieldset"} id={id}>
        {caption && <legend>{caption}</legend>}
        <div className="row">
            {containers.map(c => 
                <div className={"col-md-" + c.width} key={c.id}>
                    {c.fields.map(field => 
                        <Field {...field} 
                            showIndicator={props.showIndicator}
                            indicator={props.indicator}
                            key={"field-"+field.id}>
                        </Field> )}
                </div>
            )}
        </div>
    </fieldset>
}